import utils from '@/utils/utils'

export const formatCurrencyUSD = (value, maxDecimalPlaces) => {
  if (value == null) {
    return value
  }

  const options = {
    style: 'currency',
    currency: 'USD',
  }

  if (maxDecimalPlaces !== undefined) {
    options.maximumFractionDigits = maxDecimalPlaces
  }

  return new Intl.NumberFormat('en-US', options).format(value)
}

export const formatDateUS = (value, formatting = {
  month: 'numeric', day: 'numeric', year: 'numeric',
}) => {
  if (!value) {
    return value
  }
  return new Intl.DateTimeFormat('en-US', formatting).format(new Date(value))
}

export const formatHourBR = (value, formatting = {
  hour: 'numeric', minute: 'numeric', second: 'numeric',
}) => {
  if (!value) {
    return value
  }
  return new Intl.DateTimeFormat('pt-BR', formatting).format(new Date(value))
}
export const formatDateBR = (value, formatting = {
  month: 'numeric', day: 'numeric', year: 'numeric',
}) => {
  if (!value) {
    return value
  }
  return new Intl.DateTimeFormat('pt-BR', formatting).format(new Date(value))
}

export const formatDateSemDiaBR = (value, formatting = {
  month: 'numeric', year: 'numeric',
}) => {
  if (!value) {
    return value
  }
  return new Intl.DateTimeFormat('pt-BR', formatting).format(new Date(value))
}

export const formatDateTimeBR = (value, formatting = {
  month: 'numeric', day: 'numeric', year: 'numeric', hour: 'numeric', minute: 'numeric',
}) => {
  if (!value) {
    return value
  }
  return new Intl.DateTimeFormat('pt-BR', formatting).format(new Date(value))
}

export const formatDateTimeUTC0 = (value, formatting = {
  timeZone: 'UTC', year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric',
}) => {
  if (!value) {
    return value
  }
  return new Intl.DateTimeFormat('pt-BR', formatting).format(new Date(value))
}

export const formatDateTimeSegundoBR = (value, formatting = {
  month: 'numeric', day: 'numeric', year: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric',
}) => {
  if (!value) {
    return value
  }
  return new Intl.DateTimeFormat('pt-BR', formatting).format(new Date(value))
}

export const formatDateTimeSemAnoBR = (value, formatting = {
  month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric',
}) => {
  if (!value) {
    return value
  }
  return new Intl.DateTimeFormat('pt-BR', formatting).format(new Date(value))
}

// eslint-disable-next-line prefer-template
export const toIsoStringTimeZone = date => new Date(date.getTime()).toISOString()

export const percentDiferenca = (inicial, final) => {
  if (inicial && final) {
    return utils.percentDiff(inicial, final).toFixed(2)
  }
  return '-'
}

export const duracao = (startTime, endTime) => {
  const x = new Date(startTime)
  const now = new Date(endTime)
  let timeDiff = now - x
  timeDiff /= 1000

  const seconds = Math.round(timeDiff)
  timeDiff = Math.floor(timeDiff / 60)

  const minutes = Math.round(timeDiff % 60)
  timeDiff = Math.floor(timeDiff / 60)

  const hours = Math.round(timeDiff % 24)
  timeDiff = Math.floor(timeDiff / 24)

  const days = Math.round(timeDiff % 365)
  timeDiff = Math.floor(timeDiff / 365)

  const years = timeDiff
  let timeTemp = ''
  if (years > 0) {
    timeTemp = years + (years > 1 ? ' anos ' : ' ano ')
  } else if (days > 0) {
    timeTemp = days + (days > 1 ? ' dias ' : ' dia ')
  } else if (hours > 0) {
    timeTemp = hours + (hours > 1 ? ' horas ' : ' hora ')
  } else if (minutes > 0) {
    timeTemp = minutes + (minutes > 1 ? ' min ' : ' min ')
  } else if (seconds > 0) {
    timeTemp = seconds + (seconds > 1 ? ' segundos' : 'rápido')
  } else {
    timeTemp = 'rápido'
  }
  return timeTemp
}
