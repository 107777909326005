import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {

    getSaqueExtrato(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios.get('financeiro-indicacao/saque/extrato', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => { reject(error) })
      })
    },

    getExtratoResumo() {
      return axios.get('financeiro-indicacao/extrato-resumo')
    },

    saque(context, playload) {
      return new Promise((resolve, reject) => {
        axios.post('/financeiro-indicacao/saque', playload)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    enviarTokenSaque(context, playload) {
      return new Promise((resolve, reject) => {
        axios.post('/financeiro-indicacao/saque/enviar-token', playload)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
