<template>
  <div>
    <b-card>
      <b-card-header>
        <b-card-title>
          <div class="mb-1">
            {{ $t('indicacao.saque.title') }}
          </div>
          <h3
            v-if="resumo"
            class="font-weight-bolder"
          >
            $ {{ resumo.disponivel.toFixed(2) }}
          </h3>
        </b-card-title>
      </b-card-header>
      <b-card-body>
        <b-row>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            v-b-modal.modal-saque
            variant="success"
            :disabled="resumo.disponivel <= valorMinimoSaque"
          >
            {{ $t('indicacao.saque.solicitarSaqueBtn') }}
          </b-button>
        </b-row>
        <b-row>
          <b-alert
            variant="warning"
            show
            class="mt-2"
          >
            <div class="alert-body">
              <div class="mb-1">
                <strong>{{ $t('indicacao.saque.alertTextValorMinimo') }} </strong>$ 25
              </div>
              <div><strong>{{ $t('indicacao.saque.alertTextImportante') }} </strong>{{ $t('indicacao.saque.alertText') }}</div>
            </div>
          </b-alert>
        </b-row>
      </b-card-body>
    </b-card>
    <b-card no-body>
      <b-table
        ref="refListTable"
        :items="dataList"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        :empty-text="$t('indicacao.emptyTextTable')"
        :sort-desc.sync="isSortDirDesc"
        striped
      >
        <template #cell(dataCriacao)="data">
          {{ formatDateTimeBR(data.value) }}
        </template>

        <template #cell(qtdSolicitada)="data">
          {{ parseFloat(data.value).toFixed(3) }}
        </template>

        <template #cell(taxa)="data">
          {{ data.value }} <small>%</small>
        </template>

        <template #cell(dataPagamento)="data">
          {{ formatDateTimeBR(data.value) }}
        </template>
        <template #cell(status)="data">
          <b-badge :variant="getStatusColor(data.value)">
            {{ data.value }}
          </b-badge>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">
              {{ $t('indicacao.extrato.alertTextMuted', { from: paginationMeta.from, to: paginationMeta.to, of: paginationMeta.of }) }}
            </span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="currentPage"
              :total-rows="totalCount"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>

    <b-modal
      id="modal-saque"
      ref="modal-saque"
      :title="$t('indicacao.saque.titleSolicitarSaque')"
      hide-footer
      hide-header-close
      centered
      @show="resetModalSaque"
    >
      <b-overlay
        :show="loadingOverlayModal"
        rounded="sm"
      >
        <template v-if="!novoSaque.tokenEnviado">
          <validation-observer ref="formsaquenovo">
            <b-form
              autocomplete="off"
              @submit.prevent="enviarTokenHandler"
            >
              <label for="saque-carteira">
                {{ $t('indicacao.saque.labelValorDisponivel') }}
              </label>
              <p v-if="resumo.disponivel">
                $ {{ resumo.disponivel.toFixed(2) }}
              </p>
              <template v-if="resumo.disponivel >= valorMinimoSaque">
                <label for="saque-carteira">
                  {{ $t('indicacao.taxa') }} (2,5%)
                </label>
                <p>$ {{ calcularTaxa().toFixed(2) }}</p>
                <label for="saque-carteira">
                  {{ $t('indicacao.saque.labelValorLiquido') }} (2,5%)
                </label>
                <p class="font-weight-bolder text-success">
                  $ {{ (resumo.disponivel - calcularTaxa()).toFixed(2) }}
                </p>
                <label for="saque-moeda">
                  {{ $t('indicacao.saque.labelMoeda') }}
                </label>
                <p class="font-weight-bolder">
                  {{ $t('indicacao.saque.nomeMoeda') }}
                </p>
                <b-form-group>
                  <label for="saque-rede">
                    {{ $t('indicacao.saque.rede') }}
                  </label>
                  <validation-provider
                    #default="{ errors }"
                    name="Rede"
                    :rules="{ required: true }"
                  >
                    <b-form-select
                      v-model="novoSaque.network"
                      :options="networkOptions"
                      input-id="saque-rede"
                      :state="novoSaqueState"
                      required
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

                <b-form-group>
                  <label for="saque-carteira">
                    {{ $t('indicacao.carteira') }}
                  </label>
                  <validation-provider
                    #default="{ errors }"
                    name="Carteira"
                    :rules="{ required: true }"
                  >
                    <b-form-input
                      id="saque-carteira"
                      v-model="novoSaque.address"
                      :state="novoSaqueState"
                      required
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </template>

              <div class="modal-footer">
                <button
                  type="button"
                  class="btn btn-secondary"
                  @click="resetModalSaque"
                >
                  {{ $t('indicacao.saque.cancelarBtn') }}
                </button>
                <button
                  class="btn btn-success"
                  type="submit"
                >
                  {{ $t('indicacao.saque.confirmarSaqueBtn') }}
                </button>
              </div>
            </b-form>
          </validation-observer>
        </template>
        <template v-else>
          <b-card>
            <b-alert
              variant="warning"
              show
              class="d-flex align-items-center justify-content-center mb-3"
            >
              <div class="alert-body">
                {{ $t('indicacao.saque.alertVerificarSeguranca') }}
              </div>
            </b-alert>
            <b-card-text>
              <p>
                {{ $t('indicacao.saque.textVerificacaoSeguranca') }}
              </p>
              <p class="mt-2 text-center">

                <validation-observer ref="formtoken">
                  <b-form
                    autocomplete="off"
                    @submit.prevent="saqueSubmit"
                  >
                    <template v-if="resumo.disponivel >= valorMinimoSaque">

                      <b-form-group
                        :description="$t('indicacao.saque.descriptionCodigoVerificacao')"
                      >
                        <b-overlay
                          :show="loadingOverlayEnviar"
                          rounded="lg"
                          opacity="0.6"
                        >
                          <template v-slot:overlay>
                            <div class="d-flex align-items-center">
                              <b-spinner
                                small
                                type="grow"
                                variant="secondary"
                              />
                              <b-spinner
                                type="grow"
                                variant="dark"
                              />
                              <b-spinner
                                small
                                type="grow"
                                variant="secondary"
                              />
                            </div>
                          </template>
                          <b-input-group>
                            <b-form-input
                              v-model="novoSaque.codeVerify"
                              :state="novoSaqueState"
                              :disabled="loadingOverlayEnviar"
                              :aria-hidden="loadingOverlayEnviar ? 'true' : null"
                              max="6"
                            />
                            <b-input-group-append>
                              <b-button
                                v-ripple.400="'rgba(0, 207, 232, 0.15)'"
                                variant="outline-info"
                                :disabled="!novoSaque.tokenHabilitaReenvio"
                                @click.prevent="reenviarToken"
                              >
                                {{ !novoSaque.tokenHabilitaReenvio? $t('indicacao.enviado'): $t('indicacao.reenviar') }}
                              </b-button>
                            </b-input-group-append>

                          </b-input-group>
                        </b-overlay>
                      </b-form-group>

                    </template>

                    <div class="modal-footer mt-2 mb-2 d-flex align-items-center justify-content-center mb-3">
                      <button
                        type="button"
                        class="btn btn-secondary"
                        @click="resetModalSaque"
                      >
                        {{ $t('indicacao.saque.cancelarBtn') }}
                      </button>
                      <button
                        :disabled="!(novoSaque.codeVerify && novoSaque.codeVerify.length == 6)"
                        class="btn btn-success"
                        type="submit"
                      >
                        {{ $t('indicacao.saque.sacarBtn') }}
                      </button>
                    </div>
                  </b-form>
                </validation-observer>
              </p>
              <p
                v-if="!novoSaque.tokenHabilitaReenvio"
                class="mb-2 text-center"
              >
                {{ $t('indicacao.saque.textReenvioPrimeiraParte') }} <span class="font-weight-bolder">{{ Math.abs(progressTokenValue - progressNewTokenTime) }}</span>{{ $t('indicacao.saque.textReenvioSegundaParte') }}  <br>
                {{ $t('indicacao.saque.textReenvioTerceiraParte') }}
              </p>
            </b-card-text>
          </b-card>

        </template>
      </b-overlay>
    </b-modal>

  </div>
</template>

<script>
import {
  BCard, BCardHeader, BTable, BPagination, BRow, BCol, BBadge, BCardTitle, BButton, BModal, BCardBody, BForm, BFormGroup, BFormInput, BFormSelect, BCardText, BAlert,
  BSpinner, BInputGroup, BInputGroupAppend, BOverlay,
} from 'bootstrap-vue'
import { required } from '@validations'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import store from '@/store'
import utilsMixin from '@/mixins/utilsMixin'
import { formatDateTimeBR, formatCurrencyUSD } from '@/utils/filter'
import Ripple from 'vue-ripple-directive'
import saqueStoreModule from './saqueStoreModule'

const INDICACAO_SAQUE_STORE_MODULE_NAME = 'indicacao-saque'

export default {

  components: {
    BCard,
    BCardHeader,
    BTable,
    BPagination,
    BRow,
    BCol,
    BBadge,
    BCardTitle,
    BCardText,
    BButton,
    BModal,
    BCardBody,
    BForm,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BAlert,
    BSpinner,
    BInputGroup,
    BInputGroupAppend,
    BOverlay,
    ValidationProvider,
    ValidationObserver,
  },

  directives: {
    Ripple,
  },

  mixins: [utilsMixin],

  data() {
    return {
      formatCurrencyUSD,
      formatDateTimeBR,
      required,
      networkOptions: [
        { value: 'TRC20', text: 'Tron (TRC20)' },
      ],
      valorMinimoSaque: 25,
      loadingOverlayGrid: false,
      perPage: 10,
      totalCount: 0,
      currentPage: 1,
      sortBy: 'dataCriacao,desc',
      isSortDirDesc: true,
      dataList: null,
      resumo: null,
      novoSaqueState: null,
      novoSaque: {
        address: '',
        network: 'TRC20',
        codeVerify: '',
        tokenEnviado: false,
        tokenHabilitaReenvio: false,
      },
      progressTokenValue: 0,
      intervalProgressValue: null,
      progressTokenTry: 0,
      progressNewTokenTime: 90,
      loadingOverlayEnviar: false,
      loadingOverlayModal: false,
    }
  },

  computed: {
    tableColumns() {
      return [
        { key: 'dataCriacao', label: this.$i18n.t('indicacao.saque.dataSolicitacao') },
        { key: 'qtdSolicitada', label: this.$i18n.t('indicacao.saque.qtdSolicitada') },
        { key: 'taxa', label: this.$i18n.t('indicacao.taxa') },
        { key: 'network', label: this.$i18n.t('indicacao.saque.rede') },
        { key: 'address', label: this.$i18n.t('indicacao.carteira') },
        { key: 'status', label: this.$i18n.t('indicacao.status') },
        { key: 'qtdEnviada', label: this.$i18n.t('indicacao.saque.qtdEnviada') },
        { key: 'dataPagamento', label: this.$i18n.t('indicacao.saque.dataPagamento') },
        { key: 'idTransacaoPagamento', label: this.$i18n.t('indicacao.saque.idTransacaoPagamento') },
      ]
    },
    paginationMeta() {
      const localItemsCount = this.$refs.refListTable ? this.$refs.refListTable.localItems.length : 0
      return {
        from: this.perPage * (this.currentPage - 1) + (localItemsCount ? 1 : 0),
        to: this.perPage * (this.currentPage - 1) + localItemsCount,
        of: this.totalCount,
      }
    },
    windowWidth() {
      return this.$store.state.app.windowWidth
    },
  },

  watch: {
    currentPage() {
      this.fetchList()
      this.$refs.refListTable.refresh()
    },
  },

  created() {
    if (!store.hasModule(INDICACAO_SAQUE_STORE_MODULE_NAME)) store.registerModule(INDICACAO_SAQUE_STORE_MODULE_NAME, saqueStoreModule)
    this.fetchList()
    this.getExtratoResumo()
  },

  beforeDestroy() {
    if (store.hasModule(INDICACAO_SAQUE_STORE_MODULE_NAME)) store.unregisterModule(INDICACAO_SAQUE_STORE_MODULE_NAME)
  },

  methods: {

    clearProgressToken() {
      this.progressTokenValue = 0
      this.progressTokenTry = 0
      clearInterval(this.intervalProgressValue)
    },

    progressToken() {
      this.intervalProgressValue = setInterval(() => {
        if (this.progressTokenValue === this.progressNewTokenTime) {
          this.novoSaque.tokenHabilitaReenvio = true
          this.clearProgressToken()
        } else {
          this.progressTokenValue += 1
        }
      }, 1000)
    },

    reenviarToken() {
      this.enviarTokenSaque()
    },

    enviarTokenHandler() {
      this.enviarTokenSaque()
    },

    calcularTaxa() {
      return this.resumo.disponivel * 0.025 // 2,5% é representado como 0,025
    },

    resetModalSaque() {
      this.novoSaque = {
        address: '',
        network: '',
        codeVerify: '',
        tokenEnviado: false,
      }
      this.novoSaqueState = null
      this.clearProgressToken()
      this.$refs['modal-saque'].toggle('#toggle-btn')
    },

    saqueSubmit() {
      // Exit when the form isn't valid
      this.$refs.formtoken.validate().then(success => {
        if (success) {
          this.novoSaqueState = success
          this.saque()
        } else {
          this.novoSaqueState = success
        }
      })
    },

    saque() {
      this.loadingOverlayModal = true
      store
        .dispatch('indicacao-saque/saque', { address: this.novoSaque.address, network: this.novoSaque.network, codeVerify: this.novoSaque.codeVerify })
        .then(() => {
          this.$refs['modal-saque'].toggle('#toggle-btn')
          this.getExtratoResumo()
          this.fetchList()
        })
        .catch(e => {
          this.$swal({
            icon: 'error',
            title: 'Erro!',
            text: e.data,
            customClass: {
              confirmButton: 'btn btn-danger',
            },
          })
        })
        .finally(() => {
          this.loadingOverlayModal = false
        })
    },

    enviarTokenSaque() {
      this.loadingOverlayModal = true
      this.loadingOverlayEnviar = true
      store
        .dispatch('indicacao-saque/enviarTokenSaque', { address: this.novoSaque.address, network: this.novoSaque.network, codeVerify: this.novoSaque.codeVerify })
        .then(() => {
          this.novoSaque.tokenEnviado = true
          this.novoSaque.tokenHabilitaReenvio = false
          this.progressToken()
        })
        .catch(e => {
          this.$swal({
            icon: 'error',
            title: 'Erro!',
            text: e.data.error,
            customClass: {
              confirmButton: 'btn btn-danger',
            },
          })
        }).finally(() => {
          this.loadingOverlayModal = false
          this.loadingOverlayEnviar = false
        })
    },

    getStatusColor(value) {
      if (value === 'Aguardando pagamento') return 'warning'
      if (value === 'Pago') return 'success'
      if (value === 'Cancelado') return 'danger'
      return 'warning'
    },

    getExtratoResumo() {
      store
        .dispatch('indicacao-saque/getExtratoResumo')
        .then(response => {
          this.resumo = response.data
        })
    },

    fetchList() {
      store
        .dispatch('indicacao-saque/getSaqueExtrato', {
          size: this.perPage,
          page: this.currentPage - 1,
          sortBy: this.sortBy,
          sortDesc: this.isSortDirDesc,
        })
        .then(response => {
          const { content, totalElements } = response.data
          this.dataList = content
          this.totalCount = totalElements
        })
    },
  },
}
</script>
